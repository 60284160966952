import React from 'react'
import './Reviews.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

// Import profile pictures
import pfp1 from '../../assets/pfp/1.jpg'
import pfp2 from '../../assets/pfp/2.jpg'
import pfp3 from '../../assets/pfp/3.jpg'
import pfp4 from '../../assets/pfp/4.jpg'
import pfp5 from '../../assets/pfp/5.jpg'

/**
 * Reviews component - displays customer testimonials with profile pictures
 * Shows actual user reviews with ratings in a card-based layout
 */
const Reviews = () => {
	// Array of review data
	const testimonials = [
		{
			id: 1,
			name: 'Marta K.',
			avatar: pfp1,
			rating: 5,
			text: 'Zaczałem świadomie śnić prawie codzienie kiedy chce, Na początku było trudno, ale z czasem zaczęło się udawać..',
			date: '12 stycznia 2024',
		},
		{
			id: 2,
			name: 'Dawid T.',
			avatar: pfp2,
			rating: 4,
			text: 'Fajny poradnik, dużo się nauczyłem. Przydałoby się więcej przykładów, ale ogólnie jestem zadowolony. Powoli widzę postępy.',
			date: '3 marca 2024',
		},
		{
			id: 3,
			name: 'Anna S.',
			avatar: pfp3,
			rating: 5,
			text: 'Super sprawa! Wcześniej próbowałam różnych metod bez skutku. Te wskazówki naprawdę działają',
			date: '15 grudnia 2023',
		},
		{
			id: 4,
			name: 'Kamil W.',
			avatar: pfp4,
			rating: 4,
			text: 'Dobre wprowadzenie do tematu.  Chciałbym więcej materiałów video, ale sama treść jest ok.',
			date: '7 lutego 2024',
		},
		{
			id: 5,
			name: 'Natalia R.',
			avatar: pfp5,
			rating: 5,
			text: 'Świetne porady i praktyczne ćwiczenia. Najbardziej podoba mi się, że wszystko jest wytłumaczone w prosty sposób.',
			date: '28 listopada 2023',
		},
		{
			id: 6,
			name: 'Piotr B.',
			avatar: pfp3,
			rating: 4,
			text: 'Warto spróbować. Nie wszystko od razu się udaje, ale instrukcje są jasne i pomocne. Polecam osobom zainteresowanym tematem.',
			date: '19 stycznia 2024',
		},
	]

	// Render stars based on rating
	const renderStars = rating => {
		const stars = []
		for (let i = 0; i < rating; i++) {
			stars.push(<FontAwesomeIcon key={i} icon={faStar} className="review-star" />)
		}
		return stars
	}

	return (
		<section className="reviews-section" id="reviews">
			<div className="section-headline" data-aos="fade-up">
				<h2>
					Co mówią nasi <span>klienci</span>?
				</h2>
				<p>
					Dołącz do grona zadowolonych klientów, którzy zmienili swoje życie
					<br /> dzięki praktykowaniu świadomych snów
				</p>
			</div>

			<div className="testimonials-container" data-aos="fade-up">
				{testimonials.map(testimonial => (
					<div className="testimonial-card" key={testimonial.id}>
						<div className="quote-icon">
							<FontAwesomeIcon icon={faQuoteLeft} />
						</div>
						<div className="testimonial-content">
							<p className="testimonial-text">{testimonial.text}</p>
							<div className="testimonial-rating">{renderStars(testimonial.rating)}</div>
						</div>
						<div className="testimonial-author">
							<img src={testimonial.avatar} alt={`Zdjęcie profilowe ${testimonial.name}`} className="author-avatar" />
							<div className="author-details">
								<h4 className="author-name">{testimonial.name}</h4>
								<p className="testimonial-date">{testimonial.date}</p>
							</div>
						</div>
					</div>
				))}
			</div>

			<div className="review-cta" data-aos="fade-up">
				<a href="#pricing" className="review-cta-button">
					Dołącz do nich już dziś!
				</a>
			</div>
		</section>
	)
}

export default Reviews
